import React from "react"
import { graphql } from "gatsby"

import Footer from "../components/footer"

import SEO from "../components/seo"

import { Link } from "gatsby"
import Newsletter from "../components/newsletter"

import me from "../images/me.jpg"

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, fields, html } = markdownRemark

  return (
    <>
      <SEO title={frontmatter.title} description={frontmatter.description} canonical={frontmatter.canonical} imageUrl={frontmatter.imageUrl} />
      <header className="bg-white relative overflow-hidden py-4 text-center">
        <div className="relative pt-2 pb-2">
        <div className="max-w-screen-xl mx-auto">
          <nav className="relative flex items-center">
            <div className="flex items-center flex-1 justify-between w-full md:w-1/2 lg:w-1/4">
              <div className="block lg:flex items-center justify-between w-full md:w-auto">
                <Link to={"/"}>
                  <p className="text-xl lg:text-2xl leading-9 font-semibold text-gray-800 tracking-tight">
                    Production Ready GraphQL
                    <span className="ml-2 inline-flex relative">
                    <span aria-hidden="" className="absolute px-8 inset-0 bg-pink-300" style={{transform: "skewY(-.5deg) scaleX(1.05)"}}></span>
                    <span className="relative font-medium">{`{ blog }`}</span>
                  </span>
                  </p>
                </Link>
              </div>
            </div>
          </nav>
        </div>
        </div>
      </header>
      <div className="blog-post-container my-12">
        <div className="blog-post max-w-screen-md mx-auto">
          <h1 className="text-center text-gray-800 text-4xl font-semibold tracking-tight leading-tight">{frontmatter.title}</h1>
          <div className="text-center flex items-center justify-center mt-8 mb-4 w-full">
            <div>
              <img className="h-8 w-8 rounded-full" src={me} alt="" />
            </div>
            <div className="ml-4">
              <a href="https://twitter.com/__xuorig__" className="text-gray-600 text-xl hover:underline">Marc-Andre Giroux</a>
            </div>
          </div>
          <div className="text-center mt-4 mb-12">
            <p className="text-gray-500 text-lg">{frontmatter.date} | {fields.readingTime.text}</p>
          </div>
          <div className="blog-post-content text-gray-900" dangerouslySetInnerHTML={{ __html: html }} />

          <div className="text-gray-900 text-lg italic rounded-lg shadow-lg p-4">
           <p>
           If you've enjoyed this post, you might like the <a className="font-semibold underline text-pink-500" href="https://book.productionreadygraphql.com">Production Ready GraphQL book</a>, which I have just released!
           </p>
           <p className="pt-4">
           Thanks for reading 💚
           </p>
          </div>
        </div>
      </div>
      <Newsletter />
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        canonical
        imageUrl
      }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`